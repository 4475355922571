import React, { useState } from 'react';
import styled from 'styled-components';

import SingleCase from './SingleCase';
import { breakpoints } from '../../lib/constants';

const Container = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: 20,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
  marginTop: 30,
});

function AllCases({ items }) {
  return (
    <Container>
      {items.map(({ node }) => (
        <SingleCase key={node.id} {...node} />
      ))}
    </Container>
  );
}

export default AllCases;
