import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { breakpoints, colors } from '../lib/constants';
import AnimatedBlobSvgA from './AnimatedBlobSvgA';
import AnimatedBlobSvgB from './AnimatedBlobSvgB';

const Container = styled.div({
  position: 'relative',
  maxWidth: '100%',
  svg: {
    display: 'block',
  },
});

const Content = styled.div({
  position: 'absolute',
  padding: '5% 10%',
  top: '2%',
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'center',
});

export default ({
  children,
  fill = colors.basePink,
  animationDelay,
  variant = 'default',
  size = 220,
  responsive = false,
  contentFontSize : fixedContentFontSize,
}) => {
  const [contentFontSize, setContentFontSize] = useState(fixedContentFontSize);
  const [fontSizeReady, setFontSizeReady] = useState(!!fixedContentFontSize);
  const contentRef = useRef();
  const containerRef = useRef();
  useEffect(() => {
    if (!contentRef || fixedContentFontSize) {
      return;
    }

    let fs = 10;

    function increaseLoop() {
      if (
        contentRef.current.getBoundingClientRect().height >
          containerRef.current.getBoundingClientRect().height * 0.7 ||
        fs > 40
      ) {
        // we've found the max font-size
        setFontSizeReady(true);
        return;
      }
      fs++;
      setContentFontSize(fs);
      window.requestAnimationFrame(increaseLoop);
    }

    increaseLoop();
  }, [children, size, fixedContentFontSize]);

  return (
    <Container
      ref={containerRef}
      css={{
        width: size,
        ...(responsive
          ? {
              [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
                width: size * 0.7,
                bottom: '0',
                top: 'auto',
              },
              [`@media (max-width: 450px)`]: {
                width: size * 0.5,
                fontSize: 10,
              },
            }
          : {}),
      }}
    >
      {variant === 'default' && <AnimatedBlobSvgA fill={fill} />}
      {variant === 'funky' && <AnimatedBlobSvgB fill={fill} />}

      <Content>
        <div
          ref={contentRef}
          css={{
            fontSize: contentFontSize,
            visibility: fontSizeReady ? 'visible' : 'hidden',
          }}
        >
          {children}
        </div>
      </Content>
    </Container>
  );
};
