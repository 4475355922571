import React from 'react';

import { colors } from '../lib/constants';

const shapeAPoints =
  'm288.25 64.925c-33.74 8.5423-36.091-20.38-64.974-42.257-28.883-21.877-61.341-22.668-97.383-22.668-33-1.98e-27 -51.5 10.5-58.97 31-10.333 28.356 23.97 54.5-38.968 86.598-36.562 22.402-32.062 49.902-15.888 64.424 19.788 17.766 43.93 2.848 54.856 11.478 19.104 15.09 1.8263 39.424 9e-14 43.66-7.9736 18.497-2.9151 43.541 32.027 57.19 24.63 8.1156 43.739 3.1798 54.859-4.5769 34.083-23.774 56.441-25.623 83.352-2.1572 13.043 11.098 25.88 15.252 39.446 12.416 13.567-2.8399 22.951-9.7648 28.1-21.707 13.121-30.439-19.891-49.275-9.9454-75.55 9.9454-26.275 42.131-25.775 54.177-52.507 11.954-32.671-9.5461-99.268-60.688-85.344z';

const shapeBPoints =
  'm294.76 67.5c-58.369-20-44.986-18.623-73.869-40.5-28.883-21.877-53.457-27-89.5-27-33 1.98e-27 -57 16.5-64.47 37-10.333 28.356 23.97 44-38.968 76.098-36.562 22.402-32.062 49.902-15.888 64.424 19.788 17.766 27.4 15.848 38.326 24.478 19.104 15.09 18.356 34.763 16.53 39-7.9736 18.497-19.473 40.883 15.47 54.532 24.63 8.1156 52.379 7.7567 63.5-1e-13 34.083-23.774 63.589-45.174 90.5-21.707 13.043 11.098 26.649 24.543 40.214 21.707 13.567-2.8399 22.951-9.7648 28.1-21.707 13.121-30.439-38.046-58.55-28.1-84.825 9.9454-26.275 43.286-20.5 65.286-47 17.5-28.902 23-74.5-47.131-74.5z';

export default ({ fill = colors.basePink }) => (
  <svg viewBox="0 0 354 302">
    <path d={shapeAPoints} fillRule="nonzero" fill={fill}>
      <animate
        dur="10s"
        repeatCount="indefinite"
        attributeName="d"
        values={`${shapeAPoints};${shapeBPoints};${shapeAPoints}`}
        fill="freeze"
        calcMode="spline"
        keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
      />
    </path>
  </svg>
);
