import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import Youtube from 'react-youtube';
import styled from 'styled-components';

import {
  PrimaryHeading,
  Paragraph3,
} from '../components/reusables/textElements';
import { breakpoints, fonts, gutters, colors } from '../lib/constants';
import AllCases from '../components/case/AllCases';
import AppChrome from '../components/AppChrome';
import Blob from '../components/Blob';
import Button from '../components/reusables/Button';
import CenterChild from '../components/reusables/CenterChild';
import ContentWrapper from '../components/reusables/ContentWrapper';
import SectionTitle from '../components/reusables/SectionTitle';
import TopNav from '../components/TopNav';
import shuffleArray from '../lib/shuffleArray';

const Header = styled.div({
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  columnGap: 40,
  margin: `${gutters.vertical}px 0 ${gutters.vertical * 3}px`,

  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});
const Introduction = styled.p({});
const Meta = styled.div({
  margin: '30px 0',
  display: 'flex',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});
const Impacts = styled.div({
  display: 'grid',
  columnGap: gutters.horizontal,
  marginTop: 50,
  marginBottom: 50,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});
const Impact = styled.div({
  textAlign: 'center',
  maxWidth: 350,
  margin: '0 auto',
  h3: {
    fontSize: 50,
    lineHeight: 1,
    margin: 0,
  },
});
const Items = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 25,
  rowGap: gutters.vertical,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});
const Image = styled.div(({ i }) => ({
  marginBottom: 30,
}));
const BodyText = styled.div({
  p: {
    marginTop: 0,
  },
  marginBottom: 20,
  marginRight: 40,
});
const Quote = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 20,
  marginTop: -60, // adjust for text height
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    marginTop: 0,
  },
});
const YoutubeContainer = styled.div({
  margin: '0 auto',
  maxWidth: 1440,
  width: '100%',
  marginBottom: 40,
});
const YoutubeInner = styled.div({
  position: 'relative',
  paddingTop: '56%',
});
const YoutubeVideo = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  div: {
    height: '100%',
    width: '100%',
  },
});

const randomizeColor = () => {
  const array = [];
  array.push(colors.basePink, colors.baseRed, colors.baseGrey);
  const randomValue = array[Math.floor(Math.random() * array.length)];
  return randomValue;
};

export default function CaseTemplate({ data, pageContext }) {
  const blobFillColor = randomizeColor();
  const currentSlug = pageContext.slug;
  let allCases = data.allContentfulCase.edges;
  const currentItem = allCases.find(
    ({ node: { slug } }) => slug === currentSlug
  ).node;
  const {
    id: currentId,
    title,
    year,
    disciplines,
    heroImage,
    heroVideo,
    images,
    impactNumbers,
    introduction,
    body,
    quote,
    client,
  } = currentItem;
  allCases = allCases.filter(({ node: { id } }) => id !== currentId);
  allCases = shuffleArray(allCases);
  const items = (images || []).slice();
  if (quote) {
    items.splice(
      2,
      0,
      <Quote>
        <Blob fill={blobFillColor} size={375}>
          <div
            css={{ fontFamily: fonts.bold }}
            dangerouslySetInnerHTML={{
              __html: quote.childMarkdownRemark.html,
            }}
          />
        </Blob>
      </Quote>
    );
  }
  return (
    <AppChrome
      title={title}
      og={{
        image: heroImage.sizes.src,
        title,
        description: introduction.childMarkdownRemark.plainText,
        type: 'article',
      }}
    >
      <TopNav />
      <ContentWrapper>
        <Header>
          <div>
            <div css={{ marginBottom: 10, fontFamily: fonts.bold }}>
              {client}
            </div>
            <PrimaryHeading css={{ maxWidth: 550 }}>{title}</PrimaryHeading>
            <Meta>
              <div css={{ minWidth: 230, marginBottom: 15 }}>
                <Paragraph3 isBold>År</Paragraph3>
                <Paragraph3>{year}</Paragraph3>
              </div>
              <div>
                <Paragraph3 isBold>Innehåll</Paragraph3>
                {disciplines &&
                  disciplines.map((item, i) => (
                    <Paragraph3 key={i}>{item}</Paragraph3>
                  ))}
              </div>
            </Meta>
          </div>
          <div>
            <Introduction
              dangerouslySetInnerHTML={{
                __html: introduction.childMarkdownRemark.html,
              }}
            />
          </div>
        </Header>
      </ContentWrapper>
      <ContentWrapper>
        {heroVideo ? (
          <YoutubeContainer>
            <YoutubeInner>
              <YoutubeVideo>
                <Youtube
                  videoId={heroVideo}
                  opts={{ width: '100%', height: '100%' }}
                />
              </YoutubeVideo>
            </YoutubeInner>
          </YoutubeContainer>
        ) : (
          heroImage && <Img sizes={heroImage.sizes} />
        )}
      </ContentWrapper>
      {impactNumbers ? (
        <ContentWrapper>
          <Impacts
            css={{
              gridTemplateColumns: impactNumbers.map(() => '1fr').join(' '),
            }}
          >
            {impactNumbers.map(({ title, description }, index) => (
              <Impact key={index}>
                <h3>{title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                />
              </Impact>
            ))}
          </Impacts>
        </ContentWrapper>
      ) : (
        <div css={{ height: 30 }} />
      )}
      <ContentWrapper>
        <Items>
          <BodyText
            dangerouslySetInnerHTML={{
              __html: body.childMarkdownRemark.html,
            }}
          />
          {(items || []).map((item, i) => (
            <React.Fragment key={i}>
              {item.sizes ? (
                <Image i={i}>
                  <Img sizes={item.sizes} />
                  <p>{item.description}</p>
                </Image>
              ) : (
                item
              )}
            </React.Fragment>
          ))}
        </Items>
        <SectionTitle>Fler Case Studies</SectionTitle>
        <AllCases items={allCases.slice(0, 3)} />
        <CenterChild>
          <Button css={{ marginTop: 50 }} as={Link} to="/cases">
            ALLA CASE STUDIES
          </Button>
        </CenterChild>
      </ContentWrapper>
    </AppChrome>
  );
}

export const pageQuery = graphql`
  {
    allContentfulCase {
      edges {
        node {
          id
          title
          year
          disciplines
          slug
          client
          heroVideo
          introduction {
            childMarkdownRemark {
              html
              plainText
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          quote {
            childMarkdownRemark {
              html
            }
          }
          images {
            sizes(
              maxWidth: 2360
              maxHeight: 1640
              background: "rgb:000000"
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulSizes_withWebp
            }
            description
          }

          heroImage {
            sizes(
              maxWidth: 1180
              maxHeight: 820
              background: "rgb:000000"
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulSizes_withWebp
            }
          }

          impactNumbers {
            title
            description {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`;
