import React from 'react';

import { colors } from '../lib/constants';

const shapeAPoints =
  'm296 70c-15-20.678-12-70-81.637-70-25.863 0-36.132 0-51.338 16.671-18.025 24.329-41.025 40.651-58.731 40.651-56.294-3.9446-81.963 8.5575-90.97 33.342-9.0085 24.781-13.324 41.809-13.324 66.589 1.98e-27 27.487 22 94.172 72.955 94.172 30.455 4e-14 65.045-15.425 90.07-3e-14 15.206 7.5749 25.501 17.574 49.988 17.574 24.487-6e-14 42.987-42.499 82.987-54.499 40-12 65-39.5 59.773-64.453-7.773-39.047-44.773-59.368-59.773-80.047z';

const shapeBPoints =
  'm302.79 55c-15-20.678-36-55-81.637-55-25.863 4.02e-27 -36.132 4.02e-27 -51.338 16.671-18.025 24.329-47.819 52.329-65.525 52.329-56.294-3.9446-81.963 8.5575-90.97 33.342-9.0085 24.781-13.324 41.809-13.324 66.589 0 27.487 21.339 78.569 72.294 78.569 30.455 0 73.85-32.999 98.875-17.574 15.206 7.5749 25.501 17.574 49.988 17.574 24.487-4e-14 41.637-15.5 81.637-27.5 40-12 52.227-57.047 47-82-7.773-39.047-32-62.322-47-83z';

export default ({ fill = colors.basePink }) => (
  <svg viewBox="0 0 360 270">
    <path d={shapeAPoints} fillRule="nonzero" fill={fill}>
      <animate
        dur="10s"
        repeatCount="indefinite"
        attributeName="d"
        values={`${shapeAPoints};${shapeBPoints};${shapeAPoints}`}
        fill="freeze"
        calcMode="spline"
        keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
      />
    </path>
  </svg>
);
