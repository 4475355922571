import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { fonts } from '../../lib/constants';

const Item = styled(Link)({
  display: 'block',
  marginBottom: 35,
  width: '100%',
});
const StyledImg = styled(Img)({
  marginBottom: 20,
});
const Client = styled.div({
  fontSize: 14,
});
const Title = styled.div({ fontSize: 20, fontFamily: fonts.bold });

function SingleCase({ title, slug, heroImage, client }) {
  return (
    <Item to={`/cases/${slug}`}>
      <StyledImg alt={title} sizes={heroImage.sizes} />
      <Client>{client}</Client>
      <Title>{title}</Title>
    </Item>
  );
}

export default SingleCase;
